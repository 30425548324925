import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

export default function Html({data}) {
  const {script, html, ext_style, id} = data;

  return (
    <>
      <Helmet>
        <script defer>{script}</script>
      </Helmet>
      <Wrapper
        id={id}
        ext_style={ext_style}
        dangerouslySetInnerHTML={{__html: html}}
      />
    </>
  );
}

const Wrapper = styled.section`
  ${(props) => props.ext_style}
`;
