import React from 'react';
import styled from 'styled-components';
import {ArrowIosBackOutline} from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import {ArrowIosForwardOutline} from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';
import * as AppActions from '../../../AppActions';
import Carousel, {SWIPE_STATE} from '../../../Components/Carousel';

export default function CarouselRWD(props) {
  const {data = {}} = props;
  const {
    items,
    id = 'carousel_rwd_images',
    ext_style = '',
    ratio_width = 16,
    ratio_height = 9,
    ratio_width_mobile = 1,
    ratio_height_mobile = 1,
    max_width = 768,
    auto_swipe_duration = 0, // s
  } = data;

  return (
    <Wrapper
      id={id}
      ratio={ratio_height / ratio_width}
      ratio_mobile={ratio_height_mobile / ratio_width_mobile}
      max_width={max_width}
      ext_style={ext_style}>
      <Carousel
        width="100%"
        height="100%"
        data={items || []}
        disableSwipe={true}
        autoSwipeDuration={auto_swipe_duration * 1000} // milliseconds
        renderPrev={({currIdx, goToIndex}) => {
          return (
            <ActionButton
              direction="left"
              className="left-action-button"
              size={40}
              onClick={() => goToIndex(currIdx - 1)}>
              <ArrowIosBackOutline color="#ffffff" />
            </ActionButton>
          );
        }}
        renderNext={({currIdx, goToIndex}) => {
          return (
            <ActionButton
              direction="right"
              className="right-action-button"
              size={40}
              onClick={() => goToIndex(currIdx + 1)}>
              <ArrowIosForwardOutline color="#ffffff" />
            </ActionButton>
          );
        }}
        renderItem={({item, dimension, swipeStateRef}) => (
          <Item item={item} swipeStateRef={swipeStateRef} />
        )}
        renderDots={({currIdx, goToIndex}) => (
          <Dots
            currIdx={currIdx}
            goToIndex={goToIndex}
            dataLength={(items || []).length}
          />
        )}
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  --carousel-width: min(100vw, ${(props) => props.max_width}px);
  width: var(--carousel-width);
  height: calc(var(--carousel-width) * ${(props) => props.ratio});
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    /* mobile predefined 768 align useDetectDimension */
    height: calc(100vw * ${(props) => props.ratio_mobile});
  }

  ${(props) => props.ext_style};
`;

function Item(props) {
  const {item, dimension, swipeStateRef} = props;

  return (
    <ItemWrapper
      href={item.url}
      className="item-wrapper"
      draggable={false}
      rel="noreferrer"
      target="_blank"
      image={item.image}
      mobileImage={item.image_mobile}
      onClick={(e) => {
        if (swipeStateRef.current === SWIPE_STATE.swipe) {
          e.preventDefault();
          return;
        }

        if (!item.url) {
          e.preventDefault();
          return;
        }

        if (item.url[0] === '/') {
          e.preventDefault();
          AppActions.navigate(item.url);
        }
      }}></ItemWrapper>
  );
}

const ItemWrapper = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('${(props) => props.image}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    /* mobile predefined 768 align useDetectDimension */
    background-image: url('${(props) => props.mobileImage}');
  }
`;

const ActionButton = styled.button`
  border: 1px solid #ffffff;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  cursor: pointer;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  position: absolute;
  top: calc(50% - 16px);
  ${(props) => (props.direction === 'left' ? 'left: 20px' : 'right: 20px')}
`;

function Dots(props) {
  const {currIdx, goToIndex, dataLength} = props;

  return (
    <DotsWrapper className="dots">
      {Array.from(Array(dataLength).keys()).map((_, idx) => (
        <Dot
          key={idx}
          className="dot"
          selected={idx === currIdx}
          onClick={() => goToIndex(idx)}
        />
      ))}
    </DotsWrapper>
  );
}

const DotsWrapper = styled.div`
  position: absolute;
  right: 80px;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 10px 0px;
`;

const Dot = styled.div`
  margin: 10px 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? 'var(--primaryColor)' : '#ffffff'};
`;
