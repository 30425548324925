import React from 'react';
import styled from 'styled-components';
import * as Util from '../Util';
import {MenuAlt4} from '@styled-icons/heroicons-outline/MenuAlt4';
import {Close} from '@styled-icons/evil/Close';

export function getPaddingTop(navbar) {
  if (!navbar) {
    return 'var(--topNavBarHeight)';
  } else if (navbar.type === 'global') {
    return 'var(--topNavBarHeight)';
  } else if (navbar.type === 'solid') {
    return 'var(--topNavBarHeight)';
  } else {
    return '0px';
  }
}

export default function MktNavbar(props) {
  const {solid = false, navbar} = props;
  const [showMenuPanel, setShowMenuPanel] = React.useState(false);

  if (!navbar || navbar.type === 'global') {
    return null;
  }

  return (
    <Wrapper
      solid={navbar?.type === 'solid' || solid}
      showMenuPanel={showMenuPanel}
      ext_style={navbar.ext_style}>
      <div className="content">
        <div
          className="title"
          onClick={() => {
            if (navbar.icon_url) {
              Util.onLinkClick(navbar.icon_url);
            }
          }}>
          <img src={navbar.icon_image} alt="hishop" />
        </div>
        <div style={{flex: 1}} />

        <div className="links">
          {(navbar.links || []).map((item, idx) => {
            const last = idx === (navbar.links || []).length - 1;
            return (
              <a
                key={item.url}
                href={item.url}
                className={last ? 'btn' : 'link'}
                onClick={(e) => {
                  e.preventDefault();
                  Util.onLinkClick(item.url);
                }}>
                {item.text || 'Action'}
              </a>
            );
          })}
        </div>

        <div
          className="menu"
          onClick={() => {
            setShowMenuPanel(true);
          }}>
          <MenuAlt4 color="#ccc" size={36} />
        </div>
      </div>

      <section className="menu-panel">
        <div className="links">
          {(navbar.links || []).map((item, idx) => {
            const last = idx === (navbar.links || []).length - 1;
            return (
              <a
                key={item.url}
                href={item.url}
                className={last ? 'btn' : 'link'}
                onClick={(e) => {
                  e.preventDefault();
                  setShowMenuPanel(false);
                  Util.onLinkClick(item.url);
                }}>
                {item.text || 'Action'}
              </a>
            );
          })}
        </div>
        <div className="close" onClick={() => setShowMenuPanel(false)}>
          <Close color="#ccc" size={36} />
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  z-index: 1000;
  background-color: ${(props) => (props.solid ? 'white' : 'transparent')};
  box-shadow: ${(props) =>
    props.solid ? '0 2px 4px rgba(0,0,0,0.2)' : 'none'};
  transition: all 300ms;

  & > .content {
    flex: 1;
    max-width: 1160px;
    margin: 0px auto;
    padding: 0 20px;
    display: flex;
    align-items: center;

    & > .title {
      cursor: pointer;
      & > img {
        width: 48px;
        object-fit: contain;
      }
    }

    & .links {
      & > .link {
        color: ${(props) => (props.solid ? '#666' : 'white')};
        text-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
        margin: 0 20px;
      }

      & > .btn {
        background-color: white;
      }
    }

    & .menu {
      display: none;
      cursor: pointer;
    }

    @media (max-width: 832px) {
      & .menu {
        display: block;
      }
      & > .links {
        display: none;
      }
    }
  }

  & .menu-panel {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    padding: 40px 20px;
    overflow-y: auto;
    transform: translateY(
      ${(props) => (props.showMenuPanel ? '0px' : '-100vh')}
    );
    opacity: ${(props) => (props.showMenuPanel ? 1 : 0)};
    transition: all 300ms;

    & > .links {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > * {
        margin: 10px;
      }
    }

    & > .close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

  ${(props) => props.ext_style}
`;
